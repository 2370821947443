
import './App.scss';
import "./styles/globalStyles.css";
import { BrowserRouter as Router, Route, Routes, Redirect, Navigate, useNavigate } from "react-router-dom";
import Header from "./components/Header/Header.js";
import Footer from "./components/Footer/Footer.js";
import About from "./pages/About/About.js";
import Advocacy from "./pages/Advocacy/Advocacy.js";
import Certification from "./pages/Certification/Certification.js";
import Education from "./pages/Education/Education.js";
import Privacy from "./pages/Privacy/Privacy.js";
import Home from "./pages/Home/Home.js";
import { Fragment, useEffect, useState } from 'react';
import Membership from './pages/Membership/Membership';
import BoardMembers from './pages/BoardMembers/BoardMembers';
import Bylaws from './pages/Bylaws/Bylaws';
import ExplanationOfStructure from './pages/ExplanationOfStructure/ExplanationOfStructure';
import PasswdProtection from './components/PasswdProtection/PasswdProtection';
import Login from './pages/Login/Login';
import Profile from './pages/Profile/Profile';
import Register from './pages/Register/Register';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import get, { put } from '.';
import React from 'react';
import LoginModal from './components/LoginModal/LoginModal';
import RegisterModal from './components/RegisterModal/RegisterModal';

function App() {

  

  const [state, setState] = React.useState({
    userInfo:null,
    isLoaded:false
  });



  const [doLogin, setDoLogin] = useState(false);
  const [doRegister, setDoRegister] = useState(false);

  const changeMe = () => {
    get(`users/me`).then(res => {
      window.localStorage.setItem('user', JSON.stringify(res.data));
      setState({
        ...state,
        userInfo: res.data,
        isLoaded:true
      });

    }).catch(e => {
      window.localStorage.removeItem('jwt');
      window.localStorage.removeItem('user');
      setState({
        ...state,
        isLoaded:true
      });
    });
  };

  const handleClose = () => {
    setDoLogin(false);
    setDoRegister(false);
  }

  const handleDoRegister = () => {
    setDoLogin(false);
    setDoRegister(true);
  }

  const handleDoLogin = () => {
    setDoRegister(false);
    setDoLogin(true);
  }

  useEffect(() => {
    window.sendWelcomeEmail = (to) => {
      put('send-welcome-email', {
        to
      }); 
    }

    const script = document.createElement('script');
    script.src = "https://kit.fontawesome.com/7c6254e942.js";
    script.async = true;
    document.body.appendChild(script);

    
    let jwt = window.localStorage.getItem('jwt');
    let user = window.localStorage.getItem('user');
    if(user) user = JSON.parse(user);

    if(jwt && user) {
      changeMe();
    }
    else {
      window.localStorage.removeItem('jwt');
      window.localStorage.removeItem('user');
      setState({
        ...state,
        isLoaded:true
      });
    }
    

    return () => {
      document.body.removeChild(script);
    }

  }, []);


  return (
    <div className="App">
      {/* <PasswdProtection /> */}
      <Router>
        { state.isLoaded && 
        <Fragment>
          <LoginModal userInfo={state.userInfo} open={doLogin} close={handleClose} doRegister={handleDoRegister} />
          <RegisterModal userInfo={state.userInfo} open={doRegister} close={handleClose} doLogin={handleDoLogin}/>

          <Fragment>
            <Header userInfo={state.userInfo} login={()=>setDoLogin(true)} />
            <Routes>
            <Route path="*" element={<Navigate to="/" replace />} />
              <Route path="/" element={<Home/>}/>
              <Route path="/about" element={<About/>}/>
                <Route path="/about/board-members" element={<BoardMembers/>}/>
                <Route path="/about/bylaws" element={<Bylaws/>}/>
                <Route path="/about/explanation-of-structure" element={<ExplanationOfStructure/>}/>
              <Route path="/advocacy" element={<Advocacy/>}/>
              <Route path="/certification" element={<Certification/>}/>
              <Route path="/education" element={<Education/>}/>
              <Route path="/privacy-policy" element={<Privacy/>}/>
              <Route path="/membership" element={<Membership register={()=>setDoRegister(true)}/>}/>
              <Route path="/reset-password" element={<ResetPassword/>}/>
              <Route path="/profile" element={<Profile userInfo={state.userInfo} meChanged={changeMe}/>} />
            </Routes>
          </Fragment>
        </Fragment>
        }
        <Footer />
      </Router>
    </div>
    
  );
}

export default App;
