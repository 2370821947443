
import './Footer.scss';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import { get } from '../../index.js';
import Loader from '../Loader/Loader';
import CustomMarkdown from '../CustomMarkdown/CustomMarkdown';

function Footer() {
  
    const [pageContent, setPageContent] = React.useState(null);

    React.useEffect(() => {
      get('footer').then(res => {
        setPageContent(res);
      });
    }, []);
  
    return (
        <div className="Footer">
            { !pageContent && <Loader loading={!pageContent}/> }
            { pageContent && 
                <React.Fragment>
                    <div className="inner">
                        <div className="contact-us">
                            <h3>Contact Us</h3>
                            <CustomMarkdown content={pageContent.Contact_Us}/>
                            { pageContent.email && <p> <FontAwesomeIcon className="quote-icon" icon={faEnvelope} /><a href={`mailto:${pageContent.email}`}>{pageContent.email}</a></p> }
                            { pageContent.phone && <p> <FontAwesomeIcon className="quote-icon" icon={faPhone} /><a href={`tel:${pageContent.phone}`}>{pageContent.phone}</a></p> }
                        </div>
                        <div className="links">
                            <Link to="/">Home</Link>
                            <Link to="/about">About</Link>
                            <Link to="/about/board-members">Board Members</Link>
                            <Link to="/about/bylaws">Bylaws</Link>
                            <Link to="/about/explanation-of-structure">Explanation of Structure</Link>
                            <Link to="/advocacy">Advocacy</Link>
                            <Link to="/certification">Certification</Link>
                            <Link to="/education">Education</Link>
                            <Link to="/membership">Membership</Link>
                            <Link to="/account">Account</Link>
                        </div>
                        <div className="connect">
                            <h3>Connect with Us</h3>
                            { pageContent.Instagram_URL && <a href={pageContent.Instagram_URL} target="_blank"><i className="fab fa-instagram"></i></a> }
                            { pageContent.Twitter_URL && <a href={pageContent.Twitter_URL} target="_blank"><i className="fab fa-twitter"></i></a> }
                            { pageContent.Youtube_URL && <a href={pageContent.Youtube_URL} target="_blank"><i className="fab fa-youtube"></i></a> }
                            { pageContent.LinkedIn_URL && <a href={pageContent.LinkedIn_URL} target="_blank"><i className="fab fa-linkedin"></i></a> }
                            { pageContent.Facebook_URL && <a href={pageContent.Facebook_URL} target="_blank"><i className="fab fa-facebook"></i></a> }
                        </div>
                    </div>
                    <p className="copyright">&copy; Canadian Association of Paediatric Nurses. All rights reserved. <Link to="/privacy-policy">Privacy Policy</Link></p>
                </React.Fragment>
            }
        </div>
    
    );
  }
  
  export default Footer;
  