import './About.scss';
import React from "react";
import { get } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';

function About() {
  
  const [pageContent, setPageContent] = React.useState(null);

  React.useEffect(() => {
    document.title = "About - CAPN";

    get('about').then(res => {
      console.log(res);
      setPageContent(res);
    });
  }, []);

  return (
    <div className="About Page">
         <BannerTitle title={'About Us'}/>
        { !pageContent && <Loader loading={!pageContent}/> }
        { pageContent && 
            <React.Fragment>
                <div className="section">
                    <h2>
                        Vision
                    </h2>
                    <p>{ pageContent?.Vision }</p>
                    <h2>
                        Mission
                    </h2>
                    <p>{ pageContent?.Mission }</p>
                    <h2>
                        Goals
                    </h2>
                    <CustomMarkdown content={pageContent.Goals}/>
                    <h2>
                        Partnerships
                    </h2>
                    <CustomMarkdown content={pageContent.Partnerships}/>
                    <h2>
                        More Info
                    </h2>
                    <CustomMarkdown content={pageContent.More}/>
                </div>
                <div className="section dark">
                    <div className="tiles">
                        <div className="tile">
                            <h3>{ pageContent?.Highlight_One_Title }</h3>
                            { pageContent?.Highlight_One_Content }
                        </div>
                        <div className="tile">
                            <h3>{ pageContent?.Highlight_Two_Title }</h3>
                            { pageContent?.Highlight_Two_Content }
                        </div>
                        <div className="tile">
                            <h3>{ pageContent?.Highlight_Three_Title }</h3>
                            { pageContent?.Highlight_Three_Content }
                        </div>
                    </div>
                    <div className="tiles">
                        <div className="tile">
                            <h3>{ pageContent?.Highlight_Four_Title }</h3>
                            { pageContent?.Highlight_Four_Content }
                        </div>
                        <div className="tile">
                            <h3>{ pageContent?.Highlight_Five_Title }</h3>
                            { pageContent?.Highlight_Five_Content }
                        </div>
                        <div className="tile">
                            <h3>{ pageContent?.Highlight_Six_Title }</h3>
                            { pageContent?.Highlight_Six_Content }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        }
    </div>
  );
}

export default About;
