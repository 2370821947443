import './Education.scss';
import React, { Fragment } from "react";
import { apiAddress, get } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';

function Education(props) {
  
  const [pageContent, setPageContent] = React.useState({});

  React.useEffect(async () => {
    document.title = "Education - CAPN";

    let res = await get('education');

    let events = await get('events');
 
    events = events.filter(event => {
      // filter out events that have already happened, unless they were today.
      if(event.When) {
        event.When = new Date(event.When);
      }
      return event.When && (event.When > new Date() || (event.When.getDate() == new Date().getDate() && event.When.getMonth() == new Date().getMonth() && event.When.getFullYear() == new Date().getFullYear()));
    });

    events = events.sort((a, b) => {
      return new Date(a.When) - new Date(b.When);
    });

    setPageContent({...res, events})

  }, []);



  const getDate = (date) => {
    return date.getUTCDate();
  };

  const getMonth = (date) => {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return months[date.getUTCMonth()];
  };

  const getTime = (date) => {
    return date.getHours() + ':' + date.getMinutes();
  };

  const visitEvent = (e, event) => {
    if(event.Event_URL) {
      window.open(event.Event_URL);
    }
  };



  return (
    <div className="Education Page">
      <BannerTitle title={'Education'}/>
      { !pageContent && <Loader loading={!pageContent}/> }
      {
        pageContent &&
        <React.Fragment>
          <div className="section">
          <h1 className="leftAlign">
                Upcoming Events
            </h1>
            {
                !pageContent.events?.filter(e=>e.Show_On_Education).length ?
                <div className="no-events">No upcoming events.</div>
                :
                <div className="event-tiles">
                { pageContent.events?.filter(e=>e.Show_On_Education).map((event, index) => {
                    if(event.When) {
                      event.When = new Date(event.When);
                    }
              
                    return <div key={`event${index}`} onClick={(e) => visitEvent(e, event)} className={"event-tile" + (index == 0 ? ' primary' : '') } >
                      { event.Banner_Image?.data?.attributes?.url &&
                        <div className="banner-image">
                          <img src={`${apiAddress}${event.Banner_Image?.data?.attributes?.url}`}/>
                        </div>
                      }
                      <div className="inner">
                        <div className="upper">
                          { event.When && 
                            <Fragment>
                              {/* <div className="date">{getDate(event.When)}&nbsp;</div> */}
                              <div className="month">{getMonth(event.When)}&nbsp;{getDate(event.When)}</div>
                            </Fragment>
                          }
                          { !event.When && 
                            <Fragment>
                              <div className="date">TBA&nbsp;</div>
                            </Fragment>
                          }
                        </div>
                        <div className="lower">
                          <div className="title">{event.Name}&nbsp;</div>
                          { event.When &&
                            <div className="desc">{event.Description}&nbsp;</div>
                          }
                          
                        </div>
                      </div>
                    </div>
                })}
              </div>
            }
          </div>
          <div className="section">
            <CustomMarkdown content={pageContent.Content}/>
          </div>
        </React.Fragment>
      }
        
    </div>
  );
}

export default Education;
