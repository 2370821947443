import './Loader.scss';
import React from 'react';
import BounceLoader from "react-spinners/BounceLoader";

function Loader(props) {
  
  const color = '#ec4047';

  return (
    <div className="Loader">
      {/* <BounceLoader color={color} loading={props.loading} size={120} /> */}
    </div>
  );
}

export default Loader;
