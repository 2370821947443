import './Membership.scss';
import React, { useState } from "react";
import { apiAddress, get } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import { Link, matchRoutes, useLocation } from 'react-router-dom';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Membership(props) {

  const { innerWidth: width, innerHeight: height } = window;

  const [sliderSettings, setSliderSettings] = useState(null);

  const location = useLocation();

  const [breadcrumbs, setBreadcrumbs] = React.useState([]);
  
  const [membership, setMembership] = React.useState(null);
  const [whyBecomeAMember, setWhyBecomeAMember] = React.useState(null);
  const [memberTestimonies, setMemberTestimonies] = React.useState(null);

  React.useEffect(async () => {
    document.title = "Membership - CAPN";

    setBreadcrumbs(location.pathname.split('/').filter(crumb => crumb));

    setMembership(await get('membership'))
    setWhyBecomeAMember(await get('why-become-a-members'))
    setMemberTestimonies(await get('member-testimonies'))
    let newSliderSettings = 
    {
      dots: true,
      infinite: true,
      infinite: true,
      slidesToShow:3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: true
    };
    if(width < 1190) {
      newSliderSettings.slidesToShow = 1
    }
    setSliderSettings(newSliderSettings);

  }, []);

  const getHrefFromBreadcrumbs = (breadcrumbs, index) => {
    return '/' + breadcrumbs.slice(0, index+1).join('/');
  }

  const toggleMemberTestimony = (mt) => {
    console.log(mt);
    
    let prev = [...memberTestimonies];
    for(let pmt of prev) {
      if(pmt.createdAt == mt.createdAt) {
        pmt.expanded = !pmt.expanded
      }
    }
    setMemberTestimonies(prev);
  }

  return (
    <div className="Membership Page">
      {/* <BannerTitle title={'Membership'}/> */}
      { !membership && <Loader loading={!membership}/> }
      {
        membership && whyBecomeAMember && memberTestimonies &&
        <React.Fragment>
          <div className="big-box">
            <div className="section">
            <div className="breadcrumbs">
              <a className="breadcrumb" href='/'>
                <i className="fas fa-home"></i>
              </a>
              { breadcrumbs.map((breadcrumb, index) => {
                return <span key={`crumb${index}`}>
                  <i className="fas fa-chevron-right"></i> 
                  <Link className="breadcrumb" to={getHrefFromBreadcrumbs(breadcrumbs, index)}>{ breadcrumb.replaceAll('-',' ') }</Link>
                </span>
              }) }
            </div>
              <h1><CustomMarkdown content={membership.Title}/></h1>
              <p className="call-to-action"><CustomMarkdown content={membership.Subtitle}/></p>
              <a className="button primary" onClick={props.register}>Sign Up</a>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <a className="button secondary" href="#LearnMore">Learn More</a>
            </div> 
          </div>
          <div className="section why">
            <h1>Why Become a Member?</h1>
            <Slider {...sliderSettings} className="slider">
              {
                whyBecomeAMember?.map((wbam, index) => {
                  return <div key={`wbam${index}`} className="tile">
                    <div className="tile-inner">
                      <img src={`${apiAddress}${wbam.Image.data.attributes.url}`}></img>
                      <h3>{wbam.Title}</h3>
                      <p>
                        {wbam.Content}
                      </p>
                    </div>
                    
                  </div>
                })
              }
            </Slider>
            {/* <div className="more-accordion">
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <p>More</p>
                </AccordionSummary>
                <AccordionDetails>
                  {
                    whyBecomeAMember?.filter((wbam) => !wbam.Is_Main || !wbam.Image ).map((wbam, index) => {
                      return <div key={`wbam_minor_${index}`} className="tile">
                        <h3>{wbam.Title}</h3>
                        <p>
                          {wbam.Content}
                        </p>
                    </div>
                    })
                  }
                </AccordionDetails>
              </Accordion>
            </div> */}
           
          </div>
          <div id="LearnMore" className="grey-box">
            <div className="section policy">
              <h1>Membership Policy</h1>
              <CustomMarkdown content={membership.Membership_Policy}/>
            </div>
          </div>
          <div>
            <div className="section members-talk">
              <h1>Our Members Talk About Nursing</h1>
              <div className="big-tiles">
                {
                    memberTestimonies?.map((mt, index) => {
                      return <div key={`mt${index}`} className="row">
                        <div className="flex">
                          <div className="left">
                            <div className="img" 
                              style={{
                                backgroundImage: `url(${apiAddress}${mt.Image.data.attributes.url})`
                              }}
                            ></div>
                          </div>
                          <div className="right mobile">
                              <h3>{mt.Name}</h3>
                              <p className="intro">
                                <i>{mt.Intro}</i>
                              </p>
                            </div>
                          <div className="right desktop">
                            <h3>{mt.Name}</h3>
                            <p className="intro">
                              <i>{mt.Intro}</i>
                            </p>
                            <p className="long desktop"
                              style={{
                                display: `${mt.expanded ? 'block' : '-webkit-box'}`
                              }}
                            >
                              {mt.Body && mt.Body[0] == '"' ? mt.Body : `"${mt.Body}"`}
                            </p>
                            {/* <button className="expand" onClick={() => toggleMemberTestimony(mt)}>{mt.expanded ? 'Read Less' : 'Read More'}<ExpandMoreIcon /></button> */}
                          </div>
                        </div>
                        <p className="long mobile"
                          style={{
                            display: `${mt.expanded ? 'block' : '-webkit-box'}`
                          }}
                        >
                          {mt.Body && mt.Body[0] == '"' ? mt.Body : `"${mt.Body}"`}
                        </p>
                      </div>
                    })
                  }
              </div>
              
            </div>
          </div>
          
        </React.Fragment>
      }
        
    </div>
  );
}

export default Membership;
