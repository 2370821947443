import './PasswdProtection.scss';
import React from 'react';


function PasswdProtection(props) {

  const [state, setState] = React.useState({
    isAuthenticated:false,
    triedSubmitting:false,
    password:''
  });

  React.useEffect(async () => {
    if(localStorage.getItem("paednurse_ca_authenticated")) {
      setState(prevState => ({
        ...prevState,
        isAuthenticated:true
      }));
    }
    
  }, []);

  const handlePasswordChange = (event) => {
    if(event.target.value == '') {
      setState(prevState => ({
        ...prevState,
        password:event.target.value,
        triedSubmitting:false
      }));
    }
    else {
      setState(prevState => ({
        ...prevState,
        password:event.target.value,
      }));
    }
  }

  const handleKeyPress = (event) => {
    console.log(event);
    if (event.charCode === 13) {
      handleSubmitPassword();
    }
  }

  const handleSubmitPassword = () => {
    console.log(state);
    if(state.password == 'CAPN2022') {
      localStorage.setItem("paednurse_ca_authenticated", "true");
      setState(prevState => ({
        ...prevState,
        isAuthenticated:true
      }));
    }
    else {
      setState(prevState => ({
        ...prevState,
        triedSubmitting:true
      }));
    }
  }

  return (
    <div className="PasswdProtection">
      {!state.isAuthenticated &&
        <div className="PasswdProtectionOuter">
          <div className="PasswdProtectionInner">
            <h1>This site is password protected.</h1>
            <div className={state.triedSubmitting && !state.isAuthenticated ? 'form failed' : 'form'}>
              <input placeholder="Enter password..." type="text" value={state.password} onChange={(e) => handlePasswordChange(e)} onKeyPress={(e) => handleKeyPress(e)}/>
              <button onClick={() => handleSubmitPassword()}>Enter</button>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default PasswdProtection;
