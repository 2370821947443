import './ExplanationOfStructure.scss';
import React from "react";
import { get, apiAddress } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';

function ExplanationOfStructure() {
  
  const [pageContent, setPageContent] = React.useState({});

  React.useEffect(() => {
    document.title = "About - CAPN";
    
    get('explanation-of-structure').then(res => {
      setPageContent(res);
    });
  
  }, []);

  return (
    <div className="ExplanationOfStructure Page">
      <BannerTitle title={'Explanation of Structure'} />
      { !pageContent && <Loader loading={!pageContent}/> }
      {
        pageContent &&
        <React.Fragment>
          <div className="section">
          { pageContent.PDF && <a className='button' target="_blank" href={`${apiAddress}${pageContent.PDF.data.attributes.url}`}>View PDF</a> }
            <CustomMarkdown content={pageContent.Content}/>
          </div>
        </React.Fragment>
      }
        
    </div>
  );
}

export default ExplanationOfStructure;
