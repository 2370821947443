import './Advocacy.scss';
import React from "react";
import { get, apiAddress } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Advocacy() {
  
  const [pageContent, setPageContent] = React.useState({});

  React.useEffect(() => {
    document.title = "Advocacy - CAPN";
    get('advocacy').then(res => {
      setPageContent((prevPageContent) => { 
        return {
          ...prevPageContent,
          Introduction:res.Introduction
        };
      });
    });

    get('advocacy-statements').then(res => {
      setPageContent((prevPageContent) => { 
        return {
          ...prevPageContent,
          statements:res
        };
      });
    });
  
  }, []);

  return (
    <div className="Advocacy Page">
      <BannerTitle title={'Advocacy'} />
      { !pageContent && <Loader loading={!pageContent}/> }
      {
        pageContent &&
        <React.Fragment>
          <div className="section">
            { pageContent.Introduction && 
             <CustomMarkdown content={pageContent.Introduction}/> 
            }

            { pageContent.statements?.sort((a,b)=> {
              if(a.Order == undefined) return 1;
              if(b.Order == undefined) return -1;
              return a.Order - b.Order;
            }).map((statement, index) => {
              
              return <div className="statement-container" key={`statement${index}`}>
                {/* <h3>
                  { statement.Title }
                </h3>
                <CustomMarkdown content={statement.Statement}/>
                { statement.LinkURL && 
                  <React.Fragment>
                    <a className={statement.LinkText ? 'button' : ''} target="_blank" href={statement.LinkURL}>{ statement.LinkText ? statement.LinkText : <img src={`${apiAddress}${statement.LinkImage.url}`} /> }</a>
                    &nbsp;&nbsp;&nbsp;
                  </React.Fragment>
                }
                { statement.File && 
                <React.Fragment>
                  <a className={statement.FileName ? 'button' : ''} target="_blank" href={`${apiAddress}${statement.File.url}`}>{ statement.FileName }</a>
                </React.Fragment> }
                { index < pageContent.statements.length - 1 && <div><br/><hr/></div> } */}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h3>{statement.Title}</h3>
                  </AccordionSummary>
                  <AccordionDetails>
                    <CustomMarkdown content={statement.Statement}/>
                    { statement.LinkURL && 
                      <React.Fragment>
                        <a className={statement.LinkText ? 'button' : ''} target="_blank" href={statement.LinkURL}>{ statement.LinkText ? statement.LinkText : <img src={`${apiAddress}${statement.LinkImage.url}`} /> }</a>
                        &nbsp;&nbsp;&nbsp;
                      </React.Fragment>
                    }
                    { statement.File && 
                    <React.Fragment>
                      <a className={statement.FileName ? 'button' : ''} target="_blank" href={`${apiAddress}${statement.File.url}`}>{ statement.FileName }</a>
                    </React.Fragment> }
                  </AccordionDetails>
                </Accordion>
              </div>
            }) }
          </div>
        </React.Fragment>
      }
        
    </div>
  );
}

export default Advocacy;
