import './Certification.scss';
import React from "react";
import { apiAddress, get } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

function Certification() {
  
  const [pageContent, setPageContent] = React.useState({});

  React.useEffect(() => {
    document.title = "Certification - CAPN";

    get('certification').then(res => {
      setPageContent((prevContent) => {
        return {
          ...prevContent,
          ...res
        }
      });
    });

    get('certification-profiles').then(res => {
      setPageContent((prevContent) => {
        return {
          ...prevContent,
          Certification_Profiles:res
        }
      });
    });
  
  }, []);

  return (
    <div className="Certification Page">
      <BannerTitle title={'Certification'} />
      { !pageContent && <Loader loading={!pageContent}/> }
      {
        pageContent &&
        <React.Fragment>
          <div className="section">
            <CustomMarkdown content={pageContent.Content}/>
            {
              pageContent.Certification_Profiles?.map(profile => {
                return <div className="profile-row">
                  <div className="left desktop">
                    <img src={`${apiAddress}${profile.Image.data.attributes. url}`} />
                  </div>
                  <div className="right">
                    <div className="quote-container">
                      <FontAwesomeIcon className="quote-icon" icon={faQuoteLeft} />
                      <p>{profile.Quote}</p>
                      <FontAwesomeIcon className="quote-icon" icon={faQuoteRight} />
                    </div>
                    <div className="signature">{profile.Signature}</div>
                  </div>
                </div>
              })
            }
          </div>
        </React.Fragment>
      }
      
        
    </div>
  );
}

export default Certification;
