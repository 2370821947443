import './ResetPassword.scss';
import React, { useState } from "react";
import { apiAddress, get, post } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import { Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import Logo from '../../assets/capn2.png';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { set } from 'date-fns';

function ResetPassword(props) {

  const passwordValidator = require('password-validator');

  const [searchParams, setSearchParams] = useSearchParams();

  const [resetCode, setResetCode] = useState();

  let navigate = useNavigate();

  const [state, setState] = useState({
    triedSubmitting:false,
    username:'',
    password:'',
    passwordConfirmation:'',
    valid:false,
    didSubmit:false
  });

  const [passwordSchema, setPasswordSchema] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  React.useEffect(() => {
    document.title = "Reset Password - CAPN";

    if(searchParams.get("code")) {
      setResetCode(searchParams.get("code"));
    }

    // Create a schema
    var _passwordSchema = new passwordValidator();

    // Add properties to it
    _passwordSchema.is().min(8)                                    // Minimum length 8
      .is().max(100)                                  // Maximum length 100
      // .has().uppercase()                              // Must have uppercase letters
      // .has().lowercase()                              // Must have lowercase letters
      // .has().digits(2)                                // Must have at least 2 digits
      // .has().not().spaces()                           // Should not have spaces
      // .is().not().oneOf(['Password', 'Passw0rd', 'Password123']); // Blacklist these values
    
      setPasswordSchema(_passwordSchema);

  }, []);

  const handleUsernameChange = (event) => {
    setState(prevState => ({
      ...prevState,
      username:event.target.value,
      valid: validateForm()
    }));

    
  }

  const handlePasswordChange = (event) => {
    setState(prevState => ({
      ...prevState,
      password:event.target.value,
      valid: validateForm()
    }));
  }
  
  const handlePasswordConfirmationChange = (event) => {
    setState(prevState => {
      let newState = {
        ...prevState,
        passwordConfirmation:event.target.value
      };


      newState.valid = validateForm(newState)

      return newState;
    });
  }

  const handleSubmitResetPassword = () => {
    setIsSubmitting(true);
    post('auth/forgot-password', {
      email: state.username
    }).then(r=> {
      setIsSubmitting(false);
      setState(prev => {
        return {
          ...prev,
          didSubmit:true
        }
      })
    }).catch(e=> {
      setIsSubmitting(false);
    });

  }

  const handleSubmitNewPassword = () => {
    setIsSubmitting(true);
    post('auth/reset-password', {
      code: resetCode,
      password: state.password,
      passwordConfirmation: state.passwordConfirmation
    }).then(r=> {
      setIsSubmitting(false);
      const { jwt, user } = r;
      window.localStorage.setItem('jwt', jwt)
      window.localStorage.setItem('user', JSON.stringify(user))
      window.location.replace('profile');
    }).catch(e=> {
      setIsSubmitting(false);
    });

  }

  const validatePassword = (newState=null) => {
    if(newState) {
      return passwordSchema.validate(newState.password)
    }
    return passwordSchema.validate(state.password)
  }
  
  const validatePasswordConfirmation = (newState=null) => {
    if(newState) {
      return newState.password == newState.passwordConfirmation;
    }
    return state.password == state.passwordConfirmation;
  }

  const validateForm = (newState=null) => {
    return validatePassword(newState) && validatePasswordConfirmation(newState);
  }

  return (
    <div className="ResetPassword Page">
      <BannerTitle title={'Reset Password'}/>
      { !resetCode &&
        <div className="section">
          <div className={state.triedSubmitting && !state.isAuthenticated ? 'form failed' : 'form'}>
            { !state.didSubmit &&
              <p>Enter the email address associated with your CAPN account.</p>
            }
            { state.didSubmit &&
              <p className="submitted">Please check your email and click on the provided link to reset your password.</p>
            }
            <br/>
            <TextField value={state.username} id="outlined-basic" label="Email" variant="outlined" className="field username"  onChange={(e) => handleUsernameChange(e)}/>
            
            {
              !isSubmitting &&
              <Button onClick={() => handleSubmitResetPassword()} variant="contained" className="field" disabled={state.didSubmit}>Continue</Button>
            }
            { isSubmitting &&
              <LoadingButton loading
              startIcon={<SaveIcon />}
              variant="contained" className="field">Continue</LoadingButton>
            }
            
            
          </div> 
        </div>
      }
      { resetCode &&
        <div className="section">
          <div className={state.triedSubmitting && !state.isAuthenticated ? 'form failed' : 'form'}>
            <p>Enter a new password for your account.</p>
            <br/>
            <TextField 
              error={state.password && !validatePassword()} 
              helperText={(state.password && !validatePassword()) ? "Invalid password." : ""} 
            value={state.password} id="outlined-basic" label="Password" variant="outlined" type="password" className="field password"  onChange={(e) => handlePasswordChange(e)}/>
            <TextField 
              error={state.passwordConfirmation && !validatePasswordConfirmation()} 
              helperText={(state.passwordConfirmation && !validatePasswordConfirmation()) ? "Passwords must match." : ""} 
              value={state.passwordConfirmation} id="outlined-basic" label="Confirm Password" variant="outlined" type="password" className="field password"  onChange={(e) => handlePasswordConfirmationChange(e)} />
            {
              !isSubmitting &&
              <Button disabled={!state.valid} onClick={() => handleSubmitNewPassword()} variant="contained" className="field">Reset password</Button>
            }
            { isSubmitting &&
              <LoadingButton loading
              startIcon={<SaveIcon />}
              variant="contained" className="field">Reset password</LoadingButton>
            }
            
            
          </div> 
        </div>
      }
    </div>
  );
}

export default ResetPassword;
