import './Register.scss';
import React, { useState, Fragment, useContext } from "react";
import { apiAddress, get, post, put } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import Logo from '../../assets/capn2.png';
import  { Navigate, useLocation, useNavigate } from 'react-router-dom';
import fetch from "isomorphic-fetch";
import Cookies from "js-cookie";
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

function Register(props) {
  
  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    triedSubmitting:false,
    username:'',
    password:''
  });

  const stripePromise = loadStripe('pk_test_51KyAivEH9LzJpaoakULXq37gwmtUAh2tsDpEP5J0jsbsLihsGylVlsFnWRiQ5qViNOXNYWhX6Rdc8VoC9jr4Tzkb00BNiZ1jLq')
  const [stripeOptions, setStripeOptions] = useState({
    clientSecret:undefined,
    wallets:{
      applePay:'auto',
      googlePay:'auto'
    }
  });

  const [selectedMembershipLevel, setSelectedMembershipLevel] = useState(null)
  const [membershipLevels, setMembershipLevels] = useState([]);


  React.useEffect(async () => {
    document.title = "Register - CAPN";

    const _membershipLevels = await get('membership-levels');
    setMembershipLevels(_membershipLevels);

   
    const params = new URLSearchParams(location.search); 
    const ml = params.get('membershipLevel');

    if(ml) {
      setSelectedMembershipLevel(ml);
      if(props.userInfo && !props.userInfo.isMember) {
        createRegistrationIntent(_membershipLevels.find(l => l.id == ml).Price);
      }
    }

  }, []);

  const createRegistrationIntent = async (price) => {
    let res = await put('registration-intent', {
      amount: price,
      currency: 'cad',
      payment_method_types: ['card']
    });
    console.log(res);
    if(res?.data?.client_secret) {
      setStripeOptions({
        ...stripeOptions,
        clientSecret: res.data.client_secret
      })
    }
  }

  const membershipLevelFromID = (id) => {
    return membershipLevels.find(ml => ml.id == id);
  }

  const selectedMembershipLevelChange = (e) => {
    console.log(e.target);
    // let newSelection = membershipLevels.find(ml => ml.id == e.target.value);
    navigate({
      search: `?membershipLevel=${e.target.value}`
    })

    setSelectedMembershipLevel(e.target.value);

    createRegistrationIntent(membershipLevelFromID(e.target.value).Price);
  };

  const handleUsernameChange = (event) => {
    setState(prevState => ({
      ...prevState,
      username:event.target.value,
    }));
  }

  const handlePasswordChange = (event) => {
    setState(prevState => ({
      ...prevState,
      password:event.target.value,
    }));
  }

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      handleSubmitRegister();
    }
  }

  const handleSubmitRegister = async () => {
    // handleLogin(e) {
    //   e.preventDefault();
    //   this.setState({
    //     loading: true,
    //   });
    //   this.form.validateAll();
    //   const { dispatch, history } = this.props;
    //   if (this.checkBtn.context._errors.length === 0) {
    //     dispatch(login(this.state.username, this.state.password))
    //       .then(() => {
    //         history.push("/profile");
    //         window.location.reload();
    //       })
    //       .catch(() => {
    //         this.setState({
    //           loading: false
    //         });
    //       });
    //   } else {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // }

    try {
      let res = await post('auth/local/register', {
        username: state.username,
        email: state.username,
        password: state.password
      });

      const { jwt, user } = res.data
      window.localStorage.setItem('jwt', jwt)
      window.localStorage.setItem('user', JSON.stringify(user))
      this.$router.push('/')
    } catch(error) {
        setState(prevState => ({
          ...prevState,
          error:true,
          password:'',
        }));
    }
  }

  return (
      <div className="Register Page">
        {/* {
          props.userInfo && props.userInfo.isMember &&
          <Navigate to="/profile" />
        } */}
        {
          props.userInfo && !props.userInfo.isMember &&
          <Fragment>
            <BannerTitle title={'Checkout'}/>
            <div className="section">
              <div className="checkout-layout">
                <div className="left">
                  { selectedMembershipLevel &&
                    <div>
                      <div className="selected-ml">
                        <h3>{membershipLevelFromID(selectedMembershipLevel).Name}</h3>
                        <h3>${membershipLevelFromID(selectedMembershipLevel).Price}</h3>
                      </div>
                      <p className="ml-description">
                        {membershipLevelFromID(selectedMembershipLevel).Description}
                      </p>
                      <div className="or">
                        <span>or</span>
                        <hr/>
                      </div>
                    </div>
                  }
                  
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label">Change membership level</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      onChange={selectedMembershipLevelChange} 
                      value={selectedMembershipLevel}
                    >
                      { membershipLevels.map(ml => {
                        return <FormControlLabel value={ml.id} control={<Radio/>} label={`${ml.Name} - $${ml.Price} / yr`} />
                      })
                      }
                    
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="right">
                      { selectedMembershipLevel && stripeOptions.clientSecret &&
                        <Elements stripe={stripePromise} options={stripeOptions}>
                          <CheckoutForm />
                        </Elements>
                      }
                </div>
              </div>
            </div>
          </Fragment>
        }
        {
          !props.userInfo &&
          <Fragment>
            <BannerTitle title={'Register'}/>
            <div className="section">
              <div className={state.triedSubmitting && !state.isAuthenticated ? 'form failed' : 'form'}>
                <img src={Logo}/>
                <br/>
                <TextField value={state.username} id="outlined-basic" label="Email" variant="outlined" className="field username"  onChange={(e) => handleUsernameChange(e)} onKeyPress={(e) => handleKeyPress(e)}/>
                <TextField value={state.password} id="outlined-password-input" type="password" label="Password" variant="outlined" className="password" onChange={(e) => handlePasswordChange(e)} onKeyPress={(e) => handleKeyPress(e)}/>
                <a href="/reset-password" className="field forgot-password-link">Forgot your password?</a>
                <Button onClick={() => handleSubmitRegister()} variant="contained" className="field">Sign In</Button>
                <div className="field or-hr"><hr/><span>or</span></div>
                <Button className="field" href={`${apiAddress}/admin`} variant="outlined">Sign In to the Admin Panel</Button>
                <a className="register-link">Don't have an account? Become a member now!</a>
              </div> 
            </div>
          </Fragment>
        }
        
      </div>
    
  );
}

export default Register;
