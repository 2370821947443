import './BannerTitle.scss';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import defaultProfile from '../../assets/default-user.png';

function BannerTitle(props) {

  const location = useLocation();

  const [breadcrumbs, setBreadcrumbs] = React.useState([]);

  React.useEffect(() => {
    setBreadcrumbs(location.pathname.split('/').filter(crumb => crumb));
  }, []);

  const getHrefFromBreadcrumbs = (breadcrumbs, index) => {
    return '/' + breadcrumbs.slice(0, index+1).join('/');
  }

  return (
    <div className={`BannerTitle ${props.userInfo ? 'no-pad' : ''}`}>
      <div className="breadcrumbs">
        <a className="breadcrumb" href='/'>
          <i className="fas fa-home"></i>
        </a>
        { breadcrumbs.map((breadcrumb, index) => {
          return <span key={`crumb${index}`}>
            <i className="fas fa-chevron-right"></i> 
            <Link className="breadcrumb" to={getHrefFromBreadcrumbs(breadcrumbs, index)}>{ breadcrumb.replaceAll('-',' ') }</Link>
          </span>
        }) }
      </div>
      { props.userInfo &&
        <div>
          <div className="profile-header">
            <img src={props.userInfo.img ? props.userInfo.img : defaultProfile }></img>
            <h1><span>{props.title}</span></h1>
          </div>
          <div className="menu">
            <ul>
              <li>
                <Link to="/profile" className={window.location.pathname.includes('profile') ? 'active' : ''}>Profile</Link>
              </li>
              <li>
              <Link to="/benefits" className={window.location.pathname.includes('benefits') ? 'active' : ''}>Member Benefits</Link>
              </li>
              <li>
                
              </li>
            </ul>
          </div>
        </div>
      }
      { !props.userInfo && 
        <div>
          <h1><span>{props.title}</span></h1>
        </div>
      }
    </div>
    
  );
}

export default BannerTitle;
