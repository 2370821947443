import './LoginModal.scss';
import React from "react";
import { apiAddress, get, post, modalStyle } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import { Box, Button, Checkbox, Fade, FormControlLabel, FormGroup, Modal, TextField } from '@mui/material';
import Logo from '../../assets/capn2.png';
import { Link } from 'react-router-dom';
import  { Navigate, useNavigate, useHistory } from 'react-router-dom'

function LoginModal(props) {

    
  let navigate = useNavigate();

  const [state, setState] = React.useState({
    working:false,
    error:false,
    username:'',
    password:''
  });


  React.useEffect(() => {
    document.title = "Login - CAPN";
    if(props.open && props.userInfo) {
        navProfile();
    }
  }, [props.open]);

  const handleUsernameChange = (event) => {
    setState(prevState => ({
      ...prevState,
      username:event.target.value,
    }));
  }

  const handlePasswordChange = (event) => {
    setState(prevState => ({
      ...prevState,
      password:event.target.value,
    }));
  }

  const handleKeyPress = (event) => {
    if (event.charCode === 13) {
      handleSubmitLogin();
    }
  }

  const handleSubmitLogin = async () => {
    // handleLogin(e) {
    //   e.preventDefault();
    //   this.setState({
    //     loading: true,
    //   });
    //   this.form.validateAll();
    //   const { dispatch, history } = this.props;
    //   if (this.checkBtn.context._errors.length === 0) {
    //     dispatch(login(this.state.username, this.state.password))
    //       .then(() => {
    //         history.push("/profile");
    //         window.location.reload();
    //       })
    //       .catch(() => {
    //         this.setState({
    //           loading: false
    //         });
    //       });
    //   } else {
    //     this.setState({
    //       loading: false,
    //     });
    //   }
    // }

    setState(prevState => ({
      ...prevState,
      working:true,
      error:false
    }));

    post('auth/local', {
      identifier: state.username,
      password: state.password
    }).then(res => {
      setState(prevState => ({
        ...prevState,
        working:false
      }));
      handleClose();
      const { jwt, user } = res;
      window.localStorage.setItem('jwt', jwt)
      window.localStorage.setItem('user', JSON.stringify(user))
      window.location.replace('profile');
    }).catch(e => {
      console.log(e);
      setState(prevState => ({
        ...prevState,
        working:false,
        error:true,
      }));
    });

  }

  const handleClose = () => {
      props.close();
  }

  
    const navProfile = () => {
        //close me
        props.close();
        navigate('/profile');
    }

    const navAdmin = () => {
        props.close();
        window.open(
            `${apiAddress}/admin`, "_blank");
    }

  return (
   
        <Modal
            className="LoginModal"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={props.open}
            // onClose={}
            onClose={handleClose}
            closeAfterTransition
            // BackdropComponent={Backdrop}
            // BackdropProps={{
            // timeout: 500,
            // }}
        >
            <Fade in={props.open}>
            <Box sx={modalStyle}>
                <div className="section">
                <div className={state.triedSubmitting && !state.isAuthenticated ? 'form failed' : 'form'}>
                <img src={Logo}/>
                <br/>
                <TextField disabled={state.working} value={state.username} id="outlined-basic" label="Email" variant="outlined" className="field username"  onChange={(e) => handleUsernameChange(e)} onKeyPress={(e) => handleKeyPress(e)}/>
                <TextField disabled={state.working} value={state.password} id="outlined-password-input" type="password" label="Password" variant="outlined" className="password" onChange={(e) => handlePasswordChange(e)} onKeyPress={(e) => handleKeyPress(e)}/>
                { state.error && 
                    <p className="error">The email or password you entered is incorrect.</p>
                }
                
                <a  disabled={state.working} href="/reset-password" className="field forgot-password-link">Forgot your password?</a>
                <Button  disabled={state.working} onClick={handleSubmitLogin} variant="contained" className="field">Sign In</Button>
                <div className="field or-hr"><hr/><span>or</span></div>
                <Button  disabled={state.working} className="field" onClick={navAdmin} variant="outlined">Sign In to the Admin Panel</Button>
                <a  disabled={state.working} onClick={props.doRegister} className="register-link">Don't have an account? Become a member now!</a>
                </div> 
            </div>
            </Box>
            </Fade>
        </Modal>
      
  );
}

export default LoginModal;
