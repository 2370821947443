import './Header.scss';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import React, { Fragment, useState } from 'react';
import {get, apiAddress} from '../../index.js';
import Loader from '../Loader/Loader';
import defaultProfile from '../../assets/default-user.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSignOutAlt, faTools, faUser } from '@fortawesome/free-solid-svg-icons';
import { Drawer } from '@mui/material';

function Header(props) {
  const location = useLocation();
  let navigate = useNavigate();

  const [pageContent, setPageContent] = React.useState({
    clickedProfilePopup: false
  });

  const [drawer, setDrawer] = useState(false);
  
  React.useEffect(() => {
    get('header').then(res => {
      setPageContent({
        ...pageContent,
        ...res
      });
    });
  }, []);

  const toggleProfilePopup = () => {
    setPageContent({
      ...pageContent,
      clickedProfilePopup:!pageContent.clickedProfilePopup
    })
  };

  const logout = () => {
    window.localStorage.removeItem('jwt');
    window.localStorage.removeItem('user');
    setPageContent({
      ...pageContent,
      clickedProfilePopup:false
    })
    navigate('/');
    window.location.reload();
  };

  const navProfile = () => {
    navigate('/profile');
    setPageContent({
      ...pageContent,
      clickedProfilePopup:false
    })
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };

  return (
    <div className="Header">
      { !pageContent && <Loader loading={!pageContent}/> }
      { pageContent && 
        <React.Fragment>
          <div className="desktop">
            <Link className="logo" to="/"><img src={`${apiAddress}${pageContent?.Logo?.data.attributes.url}`}/></Link>
            <ul className="menu-bar">
              <li className="menu-item"><Link className={location.pathname == '/' ? 'active' : ''} to="/">Home</Link></li>
              <li className="menu-item"><Link className={location.pathname.includes('about') ? 'active' : ''} to="/about">About</Link></li>
              <li className="menu-item"><Link className={location.pathname.includes('advocacy') ? 'active' : ''} to="/advocacy">Advocacy</Link></li>
              <li className="menu-item"><Link className={location.pathname.includes('certification') ? 'active' : ''} to="/certification">Certification</Link></li>
              <li className="menu-item"><Link className={location.pathname.includes('education') ? 'active' : ''} to="/education">Education</Link></li>
              { !props.userInfo && 
                <li className="CTA">
                  <Link className={location.pathname.includes('membership') ? 'active membership' : 'membership'} to="/membership">Membership</Link>
                  <a className={location.pathname.includes('login') ? 'active login' : 'login'} onClick={props.login}>Already a member? Sign In</a>
                </li> 
              } 
              { props.userInfo && 
              <Fragment>
                <li><Link className={location.pathname.includes('membership') ? 'active' : ''} to="/membership">Membership</Link></li> 
                <li className="profile">
                  <button onClick={toggleProfilePopup} className="profile-button"><img src={props.userInfo.img ? props.userInfo.img.data.attributes.url : defaultProfile}/> {props.userInfo.firstName} {props.userInfo.lastName}</button>
                  { pageContent.clickedProfilePopup && 
                  <Fragment>
                      <div onClick={toggleProfilePopup} className="profile-popup-container"></div>
                      <div className="profile-popup">
                        <div className="greeting">
                          Hi, {props.userInfo.firstName}!
                        </div>
                        <hr/>
                        <a className="link" onClick={navProfile}><FontAwesomeIcon icon={faUser} /> Profile</a>
                        { /*props.userInfo.hasAdmin*/ true && 
                          <a className="link" target="_blank" href={`${apiAddress}/admin`}><FontAwesomeIcon icon={faTools} /> Admin Panel</a>
                        }
                        <a className="link" onClick={logout}><FontAwesomeIcon icon={faSignOutAlt} /> Logout</a>
                    </div>
                  </Fragment>
                    
                  }
                  
                </li> 
              </Fragment>
              } 
            </ul>
          </div>
          <div className="mobile">
            <Link className="logo" to="/"><img src={`${apiAddress}${pageContent?.Logo?.data.attributes.url}`}/></Link>
            <button className="mobile-menu" onClick={toggleDrawer(true)}>
              <FontAwesomeIcon icon={faBars} />
            </button>
            
          </div>

          <Drawer
            className="mobile"
            open={drawer}
            anchor="right"
            onClose={toggleDrawer(false)}
          >
            <ul className="menu-bar">
              { !props.userInfo && 
                <li className="CTA menu-item">
                  <Link onClick={toggleDrawer(false)} className={location.pathname.includes('membership') ? 'active membership' : 'membership'} to="/membership">Membership</Link>
                  <a className={location.pathname.includes('login') ? 'active login' : 'login'} onClick={()=>{toggleDrawer(false); props.login()}} onKeyDown={toggleDrawer(false)}>Already a member? Sign In</a>
                </li> 
              } 
              { props.userInfo && 

                <li className="profile-popup menu-item">
                    <div className="greeting">
                      Hi, {props.userInfo.firstName}!
                    </div>
                    <a className="link" onClick={()=>{navProfile(); toggleDrawer(false)}}>Profile</a>
                    <a className="link" onClick={toggleDrawer(false)} target="_blank" href={`${apiAddress}/admin`}>Admin Panel</a>
                    <a className="link" onClick={()=>{logout(); toggleDrawer(false)}}>Logout</a>
                </li>
                    
              }
              <hr/>
                   
              
              <li className="menu-item"><Link onClick={toggleDrawer(false)} className={location.pathname == '/' ? 'active' : ''} to="/">Home</Link></li>
              <li className="menu-item"><Link onClick={toggleDrawer(false)} className={location.pathname.includes('about') ? 'active' : ''} to="/about">About</Link></li>
              <li className="menu-item"><Link onClick={toggleDrawer(false)} className={location.pathname.includes('advocacy') ? 'active' : ''} to="/advocacy">Advocacy</Link></li>
              <li className="menu-item"><Link onClick={toggleDrawer(false)} className={location.pathname.includes('certification') ? 'active' : ''} to="/certification">Certification</Link></li>
              <li className="menu-item"><Link onClick={toggleDrawer(false)} className={location.pathname.includes('education') ? 'active' : ''} to="/education">Education</Link></li>
              { props.userInfo && 
                 <li className="menu-item"><Link onClick={toggleDrawer(false)} className={location.pathname.includes('membership') ? 'active' : ''} to="/membership">Membership</Link></li> 
              }
            </ul>
          </Drawer>
          
        </React.Fragment>
      }
    </div>
  );
}

export default Header;
