import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import { format } from 'date-fns'
import ResetPassword from './pages/ResetPassword/ResetPassword';

export const formatDate = (dt) => {
  
  return format(new Date(dt), 'MMMM d, yyyy')
}

export const formatDatePlusOneYear = (dt) => {
  return format(new Date(dt).setFullYear(new Date(dt).getFullYear() + 1), 'MMMM d, yyyy')
}

export const get = async function(endpoint, apiPrefix=true, customParams=undefined) {
  if(endpoint.endsWith('/')) endpoint = endpoint.slice(0, -1);
  if(endpoint.startsWith('/')) endpoint = endpoint.slice(1,0);

  let jwt = window.localStorage.getItem('jwt');
  let res;
  if(jwt) {
    res = await axios.get(`${apiAddress}${apiPrefix ? '/api' : ''}/${endpoint}`, {
      headers: {
        Authorization:
          `Bearer ${ window.localStorage.getItem('jwt')}`,
      },
      params: customParams ? customParams :{
        populate:'*',
      }
    });

  } 
  else {
    res = await axios.get(`${apiAddress}/api/${endpoint}`, { 
      params: customParams ? customParams :{
        populate:'*',
      }
    });
  }

  if(Array.isArray(res.data.data)) {
    return res.data.data.map(d => { return  {
      ...d.attributes,
      id: d.id
    }});
  }
  else if(endpoint.includes('users')) {
    return res;
  }
  else {
    return res.data.data.attributes;
  }
  
}
  
export const post = async function(endpoint, data) {
  if(endpoint.endsWith('/')) endpoint = endpoint.slice(0, -1);
  if(endpoint.startsWith('/')) endpoint = endpoint.slice(1,0);
  
  return (await axios.post(`${apiAddress}/api/${endpoint}`, data)).data;
}

export const put = async function(endpoint, data, apiPrefix=true, customParams=undefined) {
  if(endpoint.endsWith('/')) endpoint = endpoint.slice(0, -1);
  if(endpoint.startsWith('/')) endpoint = endpoint.slice(1,0);
  
  let jwt = window.localStorage.getItem('jwt');
  let res;
  if(jwt) {
    res = await axios.put(`${apiAddress}${apiPrefix ? '/api' : ''}/${endpoint}`, data, {
      headers: {
        Authorization:
          `Bearer ${ window.localStorage.getItem('jwt')}`,
      }
    });

  } 
  else {
    res = await axios.put(`${apiAddress}/api/${endpoint}`, data);
  }

  return res;
}

export default get;

// export const apiAddress = 'https://api.paednurse.nairdrie.com';
//  export const apiAddress = 'http://localhost:1337';
// export const apiAddress = 'https://437d-2607-fea8-70a3-de00-c475-5491-982c-2073.ngrok.io';
// export const apiAddress = 'https://api.paednursedemo.ca';
export const apiAddress = 'https://api.paednurse.ca';

export const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius:'1em',
  outline:0,
  p: 4,
  maxWidth:'calc(100% - 72px)',
  maxHeight:'calc(100% - 72px)',
  overflowY:'auto'
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
