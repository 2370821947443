import './Bylaws.scss';
import React, { Fragment } from "react";
import { get, apiAddress } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';

function Bylaws() {
  
  const [pageContent, setPageContent] = React.useState({});

  React.useEffect(() => {
    document.title = "About - CAPN";
    
    get('bylaw').then(res => {
      setPageContent(res);
    });
  
  }, []);

  return (
    <div className="Bylaws Page">
      <BannerTitle title={'Constitution & Bylaws'} />
      { !pageContent && <Loader loading={!pageContent}/> }
      {
        pageContent &&
        <React.Fragment>
          <div className="section">
          { pageContent.PDF?.data && 
            <Fragment>
              <a className='button' target="_blank" href={`${apiAddress}${pageContent.PDF.data.attributes.url}`}>Download PDF</a>
              <br/>
              <br/>
              <embed src={`https://drive.google.com/viewerng/viewer?embedded=true&url=${apiAddress}${pageContent.PDF.data.attributes.url}`}></embed>
            </Fragment>
           }
          </div>
        </React.Fragment>
      }
        
    </div>
  );
}

export default Bylaws;
