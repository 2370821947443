import './Home.scss';
import React, { Fragment } from "react";
import { Link } from 'react-router-dom';
import { get, apiAddress } from '../../index.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import Loader from '../../components/Loader/Loader';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';

function Home() {
  
  const [pageContent, setPageContent] = React.useState({});

  const getDate = (date) => {
    return date.getUTCDate();
  };

  const getMonth = (date) => {
    const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    return months[date.getUTCMonth()];
  };

  const getTime = (date) => {
    return date.getHours() + ':' + date.getMinutes();
  };

  const visitEvent = (e, event) => {
    if(event.Event_URL) {
      window.open(event.Event_URL);
    }
  };


  React.useEffect(async () => {
    document.title = "Home - CAPN";

    let res = await get('home');
    let events = await get('events');

    events = events.filter(event => {
      return new Date(event.When) > new Date();
    });

    events = events.sort((a, b) => {
      return new Date(a.When) - new Date(b.When);
    });

    setPageContent({...res, events})
      
  }, []);

  return (
    <div className="Home Page">
      { !pageContent && <Loader loading={!pageContent}/> }
      { pageContent && 
        <React.Fragment>
          <div className="hero">
            <div className="right"
            style={{ 
              backgroundImage: `url(${apiAddress}${pageContent.Banner?.data.attributes.url})` 
            }}>
              <div className="card desktop">
                <h1>{pageContent.BannerCardTitle}</h1>
                <hr/>
                <p>{pageContent.BannerCardParagraph}</p>
                <Link className="membership" to="/membership">{pageContent.BannerCardButton}</Link>
              </div>
            </div>
            <div className="card mobile">
              <h1>{pageContent.BannerCardTitle}</h1>
              <hr/>
              <p>{pageContent.BannerCardParagraph}</p>
              <Link className="membership" to="/membership">{pageContent.BannerCardButton}</Link>
            </div>
          </div>
          <div className="section">
            <h1><CustomMarkdown content={pageContent.Subheading}/></h1>
            <p>{pageContent.Paragraph}</p>
              <div className="tiles">
                <div className="tile">
                  <img className="desktop-inline" src={`${apiAddress}${pageContent.Item_A_Image?.data.attributes.url}`}></img>
                  <h3>{pageContent.Item_A}</h3>
                  <p>
                    {pageContent.Item_A_Text}
                  </p>
                </div>
                <div className="tile">
                <img className="desktop-inline" src={`${apiAddress}${pageContent.Item_B_Image?.data.attributes.url}`}></img>
                  <h3>{pageContent.Item_B}</h3>
                  <p>
                    {pageContent.Item_B_Text}
                  </p>
                </div>
                <div className="tile">
                <img className="desktop-inline" src={`${apiAddress}${pageContent.Item_C_Image?.data.attributes.url}`}></img>
                  <h3>{pageContent.Item_C}</h3>
                  <p>
                    {pageContent.Item_C_Text}
                  </p>
                </div>
              </div>
          </div>
          <div className="section">
            <h1 className="leftAlign">
                Upcoming Events
            </h1>
            {
                !pageContent.events?.filter(e=>e.Show_On_Home).length ?
                <div className="no-events">No upcoming events.</div>
                :
                <div className="event-tiles">
                { pageContent.events?.filter(e=>e.Show_On_Home).map((event, index) => {
                    if(event.When) {
                      event.When = new Date(event.When);
                    }
              
                    return <div key={`event${index}`} onClick={(e) => visitEvent(e, event)} className={"event-tile" + (index == 0 ? ' primary' : '') } >
                      { event.Banner_Image?.data?.attributes?.url &&
                        <div className="banner-image">
                          <img src={`${apiAddress}${event.Banner_Image?.data?.attributes?.url}`}/>
                        </div>
                      }
                      <div className="inner">
                        <div className="upper">
                          { event.When && 
                            <Fragment>
                              {/* <div className="date">{getDate(event.When)}&nbsp;</div> */}
                              <div className="month">{getMonth(event.When)}&nbsp; {getDate(event.When)}</div>
                            </Fragment>
                          }
                          { !event.When && 
                            <Fragment>
                              <div className="date">TBA&nbsp;</div>
                            </Fragment>
                          }
                        </div>
                        <div className="lower">
                          <div className="title">{event.Name}&nbsp;</div>
                          { event.When &&
                            <div className="desc">{event.Description}&nbsp;</div>
                          }
                          
                        </div>
                      </div>
              
                      
                    </div>
                })}
              </div>
            }
          </div>
          <br/>
          <div className="section dark">   
            <div className="card">
              <h1 className="leftAlign">
                A Message from our President
              </h1>
              <div className="quote-container">
                <img src={`${apiAddress}${pageContent.Message_From_President_Image?.data.attributes.url}`} />
                <FontAwesomeIcon className="quote-icon" icon={faQuoteLeft} />
                <div className="quote-text">
                  <CustomMarkdown content={pageContent.Message_From_President_Message}/>
                </div>
                <FontAwesomeIcon className="quote-icon" icon={faQuoteRight} />
                  <div className="author">
                    <i>{ pageContent.Message_From_President_Signature }</i>
                  </div>
              </div>
            </div>
          </div>
        
        </React.Fragment>
      }
      
    </div>
  );
}

export default Home;
