import './RegisterModal.scss';
import React, { useState, Fragment, useContext } from "react";
import { apiAddress, get, post, put, modalStyle } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import { Box, Button, Checkbox, Fade, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, InputLabel, MenuItem, Modal, Radio, RadioGroup, Select, TextField } from '@mui/material';
import Logo from '../../assets/vertical-logo.png';
import nurseArt from '../../assets/art_transparent.png';
import  { Navigate, useLocation, useNavigate } from 'react-router-dom';
import fetch from "isomorphic-fetch";
import Cookies from "js-cookie";
import CheckoutForm from '../../components/CheckoutForm/CheckoutForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { flexbox } from '@mui/system';
import { validate } from 'email-validator';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MuiPhoneNumber from 'material-ui-phone-number';
import { setDate } from 'date-fns';

function RegisterModal(props) {
  
  const passwordValidator = require('password-validator');

  const location = useLocation();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    triedSubmitting:false,
    firstName:'',
    lastName:'',
    username:'',
    password:'',
    phone:'',
    isCNAMember:'',
    selectedMembershipLevel:''
  });

  const [registrationStage, setRegistrationStage] = useState(1);


  const [membershipLevels, setMembershipLevels] = useState([]);

  const [passwordSchema, setPasswordSchema] = useState(null);

  React.useEffect(async () => {
    document.title = "Register - CAPN";

    const _membershipLevels = await get('membership-levels');
    setMembershipLevels(_membershipLevels);

   
    const params = new URLSearchParams(location.search); 
    const ml = params.get('membershipLevel');

    if(props.open && props.userInfo) {
        navProfile();
    }


    // Create a schema
    var _passwordSchema = new passwordValidator();

    // Add properties to it
    _passwordSchema.is().min(8)                                    // Minimum length 8
      .is().max(100)                                  // Maximum length 100
      // .has().uppercase()                              // Must have uppercase letters
      // .has().lowercase()                              // Must have lowercase letters
      // .has().digits(2)                                // Must have at least 2 digits
      // .has().not().spaces()                           // Should not have spaces
      // .is().not().oneOf(['Password', 'Passw0rd', 'Password123']); // Blacklist these values
    
      setPasswordSchema(_passwordSchema);


  }, [props.open]);



  const handleUsernameChange = (event) => {
    setState(prevState => ({
      ...prevState,
      username:event.target.value,
    }));
    
  }

  const handleFirstNameChange = (event) => {
    setState(prevState => ({
      ...prevState,
      firstName:event.target.value,
    }));
  }

  const handleLastNameChange = (event) => {
    setState(prevState => ({
      ...prevState,
      lastName:event.target.value,
    }));
  }

  const handlePasswordChange = (event) => {
    setState(prevState => ({
      ...prevState,
      password:event.target.value,
    }));
  }

  const handleAddressChange = (event) => {
    setState(prevState => ({
      ...prevState,
      address:event.target.value,
    }));
  }

  const handleCityChange = (event) => {
    setState(prevState => ({
      ...prevState,
      city:event.target.value,
    }));
  }

  const handlePhoneChange = (event) => {
    console.log(event);
    setState(prevState => ({
      ...prevState,
      phone:event.target.value,
    }));
  }

  const handleProvinceChange = (event) => {
    setState(prevState => ({
      ...prevState,
      province:event.target.value,
    }));
  }

  const handleProfessionalCategoryChange = (event) => {
    setState(prevState => ({
      ...prevState,
      professionalCategory:event.target.value,
    }));
  }

  const handleWorkOrganizationChange = (event) => {
    setState(prevState => ({
      ...prevState,
      workOrganization:event.target.value,
    }));
  }

  const handleHighestLevelOfNursingChange = (event) => {
    setState(prevState => ({
      ...prevState,
      highestLevelOfNursing:event.target.value,
    }));
  }

  const handleIsCNAMemberChange = (event) => {
    setState(prevState => ({
      ...prevState,
      isCNAMember:event.target.value,
    }));
  }

  const handlePrimaryWorkSectorChange = (event) => {
    setState(prevState => ({
      ...prevState,
      primaryWorkSector:event.target.value,
    }));
  }

  const handleNursingExpertiseChange = (event) => {
    setState(prevState => ({
      ...prevState,
      nursingExpertise:event.target.value,
    }));
  }

  const handleNotesChange = (event) => {
    setState(prevState => ({
      ...prevState,
      notes:event.target.value,
    }));
  }

  const navProfile = () => {
    //close me
    props.close();
    navigate('/profile');
  }

    const handleClose = () => {
        props.close();
    }

    const doContinue = () => {
      if(!validateRegistration()) {
        setState((prev)=>({
          ...prev,
          triedSubmitting:true
        }));

        return;
      }

      if(registrationStage == 2) {
        handleSubmitRegister();
      }
      else {
        setState((prev)=>({
          ...prev,
          triedSubmitting:false
        }));
        setRegistrationStage(registrationStage+1)
      }

    }

    const doGoBack = () => {
      setRegistrationStage(registrationStage-1)
    }



    const validateFirstName = () => {
      return state.firstName.length > 0;
    }
    
    const validateLastName = () => {
      return state.lastName.length > 0;
    }
    
    const validateUsername = () => {
      return validate(state.username);
    }
    
    const validatePassword = (list) => {
      return passwordSchema.validate(state.password,  { list })
    }

    const validatePhone = () => {
      return !!state.phone
    }

    const validateAddress = () => {
      return !!state.address;
    }

    const validateCity = () => {
      return !!state.city;
    }

    const validateProvince = () => {
      return !!state.province;
    }

    const validateWorkOrganization = () => {
      return !!state.workOrganization;
    }

    const validateHighestLevelOfNursing = () => {
      return true;
    }

    const validateProfessionalCategory = () => {
      return !!state.professionalCategory;
    }

    const validateIsCNAMember = () => {
      return !!state.isCNAMember;
    }

    const validatePrimaryWorkSector = () => {
      return !!state.primaryWorkSector;
    }

    const validateAreasOfExpertise = () => {
      return true;
    }

    const validateNotes = () => {
      return true;
    }

    const validateRegistration = () => {
      if(registrationStage == 1) {
        return validateFirstName() &&
        validateLastName() &&
        validateUsername() &&
        validatePassword()
      }
      else {
        return validateFirstName() &&
        validateLastName() &&
        validateUsername() &&
        validatePassword() &&
        validatePhone() && 
        validateAddress() &&
        validateCity() &&
        validateProvince() &&
        validateProfessionalCategory() &&
        validateWorkOrganization() &&
        validateHighestLevelOfNursing() &&
        validateIsCNAMember() &&
        validatePrimaryWorkSector() &&
        validateAreasOfExpertise() &&
        validateNotes()
      }
    }
  
    const handleSubmitRegister = async () => {
      post('auth/local/register', {
        username: state.username,
        email: state.username,
        password: state.password
      }).then(async (res)=> {
        console.log(res);
        handleClose();
        const { jwt, user } = res;
        window.localStorage.setItem('jwt', jwt)
        window.localStorage.setItem('user', JSON.stringify(user))
        
        await put('update-me', {
          firstName: state.firstName,
          lastName: state.lastName,
          phone: state.phone,
          address:state.address,
          city:state.city,
          province:state.province,
          professionalCategory:state.professionalCategory,
          workOrganization:state.workOrganization,
          highestLevelOfNursing:state.highestLevelOfNursing,
          isCNAMember:state.isCNAMember,
          primaryWorkSector:state.primaryWorkSector,
          nursingExpertise:state.nursingExpertise,
          notes:state.notes
        }); 
        
        window.location.replace('profile');
  
      }).catch(e=> {
        setState(prevState => ({
          ...prevState,
          error: e.response?.data?.error?.message
        }));

        if(e.response?.data?.error?.message == 'Email is already taken') {
          setRegistrationStage(1);
        }
      });
      
        
  
    }


  return (
    <Modal
        className="RegisterModal"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={props.open}
        // onClose={}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        // timeout: 500,
        // }}
    >
      <Fade in={props.open}>
        <Box sx={modalStyle}>
          <div className="register-modal">
            {
              registrationStage == 1 &&
            
              <div className="inner">
                <div className="left desktop">
                    <img className="logo" src={Logo}/>
                    <img className="lower-art" src={nurseArt}/>
                </div>
                <div className="right">
               
                    <div className="form">
                      <div className="header">
                          <div className="subtitle">BECOME A MEMBER OF</div>
                      </div>
                      <h1>The Canadian Association of Paediatric Nurses</h1>
                      <div>
                        Already a member? <a disabled={state.working} onClick={props.doLogin} className="register-link">Log in</a>
                      </div>
                      <br/>
                      <br/>
                      <TextField 
                        error={state.triedSubmitting && !validateFirstName()} 
                        helperText={(state.triedSubmitting && !validateFirstName()) ? "Invalid first name." : ""} 
                        value={state.firstName} id="outlined-basic" label="First name" variant="outlined" className="field firstName"  onChange={(e) => handleFirstNameChange(e)}/>
                      <TextField 
                        error={state.triedSubmitting && !validateLastName()} 
                        helperText={(state.triedSubmitting && !validateLastName()) ? "Invalid last name." : ""} 
                        value={state.lastName} id="outlined-basic" label="Last name" variant="outlined" className="field lastName"  onChange={(e) => handleLastNameChange(e)}/>
                      <TextField 
                        error={state.triedSubmitting && !validateUsername()} 
                        helperText={(state.triedSubmitting && !validateUsername()) ? "Invalid email address." : ""} 
                        value={state.username} id="outlined-basic" label="Email" variant="outlined" className="field username"  onChange={(e) => handleUsernameChange(e)}/>
                      <TextField 
                        error={state.triedSubmitting && !validatePassword()} 
                        helperText={(state.triedSubmitting && !validatePassword()) ? "Invalid password. Min. 8 characters." : ""} 
                        value={state.password} id="outlined-password-input" type="password" label="Password" variant="outlined" className="field password" onChange={(e) => handlePasswordChange(e)}/>
                      { state.error && 
                        <p className="error">{state.error}</p>
                      }
                    
                      <Button onClick={() => doContinue()} variant="contained" className="field">CONTINUE</Button>
                    </div>                     
                </div>
              </div>
            }
            { registrationStage == 2 && 
              <div className="inner">
                <div className="left form">
                    <div className="header">
                        <Button className="backButton" onClick={()=> doGoBack()}>&larr; BACK</Button><br/>
                        <span className="subtitle">BECOME A MEMBER OF</span>
                    </div>
                    <h1 className="no-margin">The Canadian Association of Paediatric Nurses</h1>
                    <br/>
                    <TextField 
                      error={state.triedSubmitting && !validatePhone()} 
                      helperText={(state.triedSubmitting && !validatePhone()) ? "Invalid phone number." : ""} 
                      value={state.phone} id="outlined-basic" label="Phone number" variant="outlined" className="field address"  onChange={(e) => handlePhoneChange(e)}/>
                    <TextField 
                      error={state.triedSubmitting && !validateAddress()} 
                      helperText={(state.triedSubmitting && !validateAddress()) ? "Invalid address." : ""} 
                      value={state.address} id="outlined-basic" label="Street Address" variant="outlined" className="field address"  onChange={(e) => handleAddressChange(e)}/>
                    <TextField 
                      error={state.triedSubmitting && !validateCity()} 
                      helperText={(state.triedSubmitting && !validateCity()) ? "Invalid city." : ""} 
                      value={state.city} id="outlined-basic" label="City" variant="outlined" className="field address"  onChange={(e) => handleCityChange(e)}/>

                    <FormControl className="field" fullWidth error={state.triedSubmitting && !validateProvince()} >
                      <InputLabel id="demo-simple-select-label">Province</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.province}
                        label="Province"
                        onChange={handleProvinceChange}
                      >
                        <MenuItem value={"Alberta"}>Alberta</MenuItem>
                        <MenuItem value={"British Columbia"}>British Columbia</MenuItem>
                        <MenuItem value={"Manitoba"}>Manitoba</MenuItem>
                        <MenuItem value={"New Brunswick"}>New Brunswick</MenuItem>
                        <MenuItem value={"Newfoundland and Labrador"}>Newfoundland and Labrador</MenuItem>
                        <MenuItem value={"Northwest Territories"}>Northwest Territories</MenuItem>
                        <MenuItem value={"Nova Scotia"}>Nova Scotia</MenuItem>
                        <MenuItem value={"Nunavut"}>Nunavut</MenuItem>
                        <MenuItem value={"Ontario"}>Ontario</MenuItem>
                        <MenuItem value={"Prince Edward Island"}>Prince Edward Island</MenuItem>
                        <MenuItem value={"Quebec"}>Quebec</MenuItem>
                        <MenuItem value={"Saskatchewan"}>Saskatchewan</MenuItem>
                        <MenuItem value={"Yukon"}>Yukon</MenuItem>
                      </Select>
                      {
                        state.triedSubmitting && !validateAddress() &&
                        <FormHelperText>Invalid province.</FormHelperText>
                      }
                    </FormControl>
                    <FormControl fullWidth className="field" error={state.triedSubmitting && !validateProfessionalCategory()} >
                      <InputLabel id="demo-simple-select-label">Professional category</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.professionalCategory}
                        label="Primary work sector"
                        onChange={handleProfessionalCategoryChange}
                      >
                        <MenuItem value={"RN"}>RN</MenuItem>
                        <MenuItem value={"RPN/LPN"}>RPN/LPN</MenuItem>
                        <MenuItem value={"NP"}>NP</MenuItem>
                        <MenuItem value={"Student"}>Student</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                      {
                        state.triedSubmitting && !validateProfessionalCategory() &&
                        <FormHelperText>Invalid professional category.</FormHelperText>
                      }
                    </FormControl>
                    <TextField 
                      error={state.triedSubmitting && !validateWorkOrganization()} 
                      helperText={(state.triedSubmitting && !validateWorkOrganization()) ? "Invalid workplace." : ""} 
                      value={state.workOrganization} id="outlined-basic" label="Workplace organization" variant="outlined" className="field address"  onChange={(e) => handleWorkOrganizationChange(e)}/>
                </div>
                <div className="right form">
                  <TextField 
                        error={state.triedSubmitting && !validateHighestLevelOfNursing()} 
                        helperText={(state.triedSubmitting && !validateHighestLevelOfNursing()) ? "Invalid highest level of nursing." : ""} 
                        value={state.highestLevelOfNursing} id="outlined-basic" label="Highest level of nursing" variant="outlined" className="field address"  onChange={(e) => handleHighestLevelOfNursingChange(e)}/>
                  <FormControl fullWidth className="field"  error={state.triedSubmitting && !validateIsCNAMember()} >
                      <InputLabel id="demo-simple-select-label">CNA member?</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.isCNAMember}
                        label="CNA Member"
                        onChange={handleIsCNAMemberChange}
                      >
                        <MenuItem value={"Yes"}>Yes</MenuItem>
                        <MenuItem value={"No"}>No</MenuItem>
                      </Select>
                      {
                        state.triedSubmitting && !validateIsCNAMember() &&
                        <FormHelperText>Invalid choice.</FormHelperText>
                      }
                    </FormControl>
                    <FormControl fullWidth className="field"  error={state.triedSubmitting && !validatePrimaryWorkSector()} >
                      <InputLabel id="demo-simple-select-label">Primary work sector</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={state.primaryWorkSector}
                        label="Primary Work Sector"
                        onChange={handlePrimaryWorkSectorChange}
                      >
                        <MenuItem value={"Acute Care (Hospital)"}>Acute Care (Hospital)</MenuItem>
                        <MenuItem value={"Clinical / Medical Office"}>Clinical / Medical Office</MenuItem>
                        <MenuItem value={"College / University"}>College / University</MenuItem>
                        <MenuItem value={"Community / Home Care"}>Community / Home Care</MenuItem>
                        <MenuItem value={"Government"}>Government</MenuItem>
                        <MenuItem value={"Outpost"}>Outpost</MenuItem>
                        <MenuItem value={"Public Health"}>Public Health</MenuItem>
                        <MenuItem value={"Management"}>Management</MenuItem>
                        <MenuItem value={"Rehabilitation"}>Rehabilitation</MenuItem>
                        <MenuItem value={"Research"}>Research</MenuItem>
                        <MenuItem value={"Staff Development"}>Staff Development</MenuItem>
                        <MenuItem value={"Other"}>Other</MenuItem>
                      </Select>
                      {
                        state.triedSubmitting && !validatePrimaryWorkSector() &&
                        <FormHelperText>Invalid primary work sector.</FormHelperText>
                      }
                    </FormControl>
                    
                    <TextField 
                      value={state.nursingExpertise} id="outlined-basic" label="Areas of interest/expertise in paediatric nursing" variant="outlined" className="field address"  onChange={(e) => handleNursingExpertiseChange(e)}/>
                    
                    <TextField 
                      value={state.notes} id="outlined-basic" label="Notes" variant="outlined" className="field address"  multiline
                      rows={4} onChange={(e) => handleNotesChange(e)}/>
                    
                    <Button onClick={() => doContinue()} variant="contained" className="field">SIGN UP</Button>
                </div> 
              </div>
            }
          </div>
        </Box>
      </Fade>
    </Modal>

  );
}

export default RegisterModal;
