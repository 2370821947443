import './Profile.scss';
import React, { useState } from "react";
import  { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { apiAddress, formatDate, formatDatePlusOneYear, get, post, put } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import Logo from '../../assets/capn2.png';
import { Link } from 'react-router-dom';
import defaultProfile from '../../assets/default-user.png';
import PasswordStrengthBar from 'react-password-strength-bar';
import { validate } from 'react-email-validator';
import randomColor from "randomcolor";
import axios from 'axios';

function Profile(props) {
  const meChanged = props.meChanged;

  const location = useLocation();
  const navigate = useNavigate();
  
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  const [tab, setTab] = useState('profile');
  const [membershipBenefits, setMembershipBenefits] = useState([]);
  const [isMember, setIsMember] = useState(false);
  const [membershipLevels, setMembershipLevels] = useState([]);
  const [selectedMembershipLevel, setSelectedMembershipLevel] = useState(null);
  const [me, setMe] = useState(null);

  const selectedMembershipLevelChange = (e) => {
    console.log(e.target);
    // let newSelection = membershipLevels.find(ml => ml.id == e.target.value);
    setSelectedMembershipLevel(e.target.value);
  };

  const validateFirstName = () => {
    return me?.firstName && me.firstName.length;
  }

  const changeFirstName = (e) => {
    setMe((oldMe)=> {
      return {
        ...oldMe,
        firstName:e.target.value
      }
    })
  };

  const validateLastName = () => {
    return me?.lastName && me.lastName.length;
  }

  const changeLastName = (e) => {
    setMe((oldMe)=> {
      return {
        ...oldMe,
        lastName:e.target.value
      }
    })
  };

  const validateEmail = () => {
    return validate(me.email);
  }

  const changeEmail = (e) => {
    setMe((oldMe)=> {
      return {
        ...oldMe,
        email:e.target.value
      }
    })
  };


  const validateConfirmPassword = () => {
    return me.password == me.confirmPassword;
  }

  const changeConfirmPassword = (e) => {
    setMe((oldMe)=> {
      return {
        ...oldMe,
        confirmPassword:e.target.value
      }
    })
  };

  const validateForm = (e) => {
    return validateFirstName() 
      && validateLastName()
      && validateConfirmPassword()
      && didFormChange()
      
  };

  const goManageMembership = () => {
    put('manage-membership').then(res =>  {
      window.location.replace(res.data);
    });
  }

  const didFormChange = () => {
    return props.userInfo.firstName != me.firstName 
    || props.userInfo.lastName != me.lastName 
    // || props.userInfo.email != me.email 
    // || me.password.length
  }

  const submitForm = async () => {
    await put('update-me', me);
    if(me.password) {
      axios
      .post(`${apiAddress}/api/auth/reset-password`, {
        code: 'privateCode', // code contained in the reset link of step 3.
        password: me.password,
        passwordConfirmation: me.confirmPassword,
      })
      .then((response) => {
        console.log("Your user's password has been reset.");
      })
      .catch((error) => {
        console.log('An error occurred:', error.response);
      });
    }
    meChanged();
  };

  const getHrefFromBreadcrumbs = (breadcrumbs, index) => {
    return '/' + breadcrumbs.slice(0, index+1).join('/');
  }

  const routeProfile = () => {
    setTab('profile')
  };
  const routeBenefits = () => {
    setTab('benefits')
  };

  const datePlusOneYear = (dt) => {
    const date = new Date(dt);
    date.setFullYear(date.getFullYear() + 1);
    return date.toISOString();
  }

  const membershipLevelFromID = (id) => {
    return membershipLevels.find(ml => ml.id == id);
  }

  const resetPassword = () => {
    navigate('/reset-password')
  }

  const copyCode = (mb) => {
    
    navigator.clipboard.writeText(mb.Code).then(function() {
      console.log('Async: Copying to clipboard was successful!');
    }, function(err) {
      console.error('Async: Could not copy text: ', err);
    });

    let prev = [...membershipBenefits];
    for(let pmb of prev) {
      if(pmb.createdAt == mb.createdAt) {
        pmb.copying = true
      }
    }
    setMembershipBenefits(prev);

    setTimeout(() => {
      for(let pmb of prev) {
        if(pmb.createdAt == mb.createdAt) {
          pmb.copying = false
        }
      }
      setMembershipBenefits(prev);
    }, 1000)
  }

  const visitLink = (mb) => {
    //visit mb.Link in a new tab
    window.open(mb.Link, '_blank');
  }

  const goCheckout = () => {
    // navigate({
    //   pathname: '/register',
    //   search: `?membershipLevel=${selectedMembershipLevel}`,
    // });
    put('registration-intent', {
      selectedMembershipLevel,
    }).then(res =>  {
      window.location.replace(res.data);
    });
  };

  React.useEffect(async () => {
    if(!props.userInfo) return;

    document.title = `${props.userInfo?.firstName} ${props.userInfo?.lastName} - CAPN`;
    setTab(window.location.pathname.includes('profile') ? 'profile' : 'benefits');
    setBreadcrumbs(location.pathname.split('/').filter(crumb => crumb));
    setIsMember(props.userInfo?.isMember);
    let _isMember = true;
    let _membershipBenefits = await get('membership-benefits').catch(e => {
      if(e.response.status == 403) {
        _isMember = false;
      }
    }).finally(()=> {
      setIsMember(_isMember);
    });

    if(_membershipBenefits) {
      for(let mb of _membershipBenefits) {
        mb.color = randomColor({
          luminosity: 'light'
        });
      }
    }


    setMembershipBenefits(_membershipBenefits);
    setMembershipLevels(await get('membership-levels'));

    setMe({
      firstName:props.userInfo.firstName,
      lastName:props.userInfo.lastName,
      email:props.userInfo.email,
      isMember: props.userInfo.isMember,
      membershipChangeDate: props.userInfo.membershipChangeDate,
      customerID: props.userInfo.customerID
    });

  }, []);


  return (
    <div className="Profile Page">
      {
        !props.userInfo &&
        <Navigate to="/login" />
      }
      { me && 
        <React.Fragment>
          <div className={`BannerTitle ${me ? 'no-pad' : ''}`}>
            <div className="breadcrumbs">
              <a className="breadcrumb" href='/'>
                <i className="fas fa-home"></i>
              </a>
              { breadcrumbs.map((breadcrumb, index) => {
                return <span key={`crumb${index}`}>
                  <i className="fas fa-chevron-right"></i> 
                  <Link className="breadcrumb" to={getHrefFromBreadcrumbs(breadcrumbs, index)}>{ breadcrumb.replaceAll('-',' ') }</Link>
                </span>
              }) }
            </div>
            
            <div className="profile-header">
              <h3 style={{marginBottom:0,marginTop:'2em'}}>Welcome,</h3>
              {/* <img src={me.img ? me.img : defaultProfile }></img> */}
              <h1><span>{`${props.userInfo.firstName} ${props.userInfo.lastName}`}</span></h1>
            </div>
            <div className="menu">
              <ul>
                <li>
                  <a onClick={routeProfile} className={tab == 'profile' ? 'active' : ''}>Profile</a>
                </li>
                <li>
                  <a onClick={routeBenefits} className={tab == 'benefits' ? 'active' : ''}>Member Benefits</a>
                </li>
                <li>
                  
                </li>
              </ul>
            </div>
      
          </div>
          { tab == 'profile' &&
            <div className="section profile">
              <div className="status-section">
                <h3>Membership Status</h3>
                {
                  !me.isMember &&
                  <div>
                    <div className="status-badge inactive">
                      Inactive
                    </div>
                    {
                      !me.membershipChangeDate &&
                      <p>
                        You have not activated your membership yet. 
                      </p>
                    }
                    {
                      me.membershipChangeDate &&
                      <p>
                        Your membership expired on <i>{formatDate(me.membershipChangeDate)}</i>
                      </p>
                    }
                    <h3>Activate Membership</h3>
                    <FormControl>
                      <FormLabel id="demo-radio-buttons-group-label">Select a membership level:</FormLabel>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        name="radio-buttons-group"
                        onChange={selectedMembershipLevelChange} 
                        value={selectedMembershipLevel}
                      >
                        { membershipLevels.map(ml => {
                          return <FormControlLabel value={ml.id} control={<Radio/>} label={`${ml.Name} - $${ml.Price} / yr`} />
                        })
                        }
                     
                      </RadioGroup>
                    </FormControl>
                    <br/>
                    <br/>
                    { !selectedMembershipLevel && 
                      <Button disabled variant="contained">Checkout</Button>
                    }
                    { selectedMembershipLevel && 
                      <Button variant="contained" onClick={goCheckout}>${membershipLevelFromID(selectedMembershipLevel).Price} - Checkout</Button>
                    }
                  </div>
                }
                {
                  me.isMember &&
                  <div>
                    <div className="status-badge active">
                      Active
                    </div>
                    <p>
                      You renewed your membership on  <i>{formatDate(me.membershipChangeDate)}</i>
                    </p>
                    { me.customerID &&
                      <Button variant="contained" onClick={goManageMembership}>Manage Membership</Button>
                    }
                    { !me.customerID &&
                      <div>
                        <p>You will have to manually renew your membership before <i>{formatDatePlusOneYear(me.membershipChangeDate)}</i></p>
                        <FormControl>
                          <FormLabel id="demo-radio-buttons-group-label">Select a membership level:</FormLabel>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                            onChange={selectedMembershipLevelChange} 
                            value={selectedMembershipLevel}
                          >
                            { membershipLevels.map(ml => {
                              return <FormControlLabel value={ml.id} control={<Radio/>} label={`${ml.Name} - $${ml.Price} / yr`} />
                            })
                            }
                        
                          </RadioGroup>
                        </FormControl>
                        <br/>
                        <br/>
                        { !selectedMembershipLevel && 
                          <Button disabled variant="contained">Checkout</Button>
                        }
                        { selectedMembershipLevel && 
                          <Button variant="contained" onClick={goCheckout}>${membershipLevelFromID(selectedMembershipLevel).Price} - Checkout</Button>
                        }
                      </div>
                    }
                    
                   

                  </div>
                }
              </div>
              <div className='separator desktop'></div>
              <div className="account-section">
                <h3>Your Account</h3>
                <div className="form">
                  <Button  variant="contained" onClick={resetPassword}>Reset Password</Button>
                  <br/>
                  <br/>
                  <TextField 
                    className="form-field" 
                    id="outlined-basic" 
                    label="First name" 
                    variant="outlined" 
                    value={me.firstName} 
                    onChange={changeFirstName} 
                    error={!validateFirstName()} 
                    helperText={!validateFirstName() ? "Invalid first name." : ""} 
                  />
                  <TextField 
                    className="form-field" 
                    id="outlined-basic" 
                    label="Last name" 
                    variant="outlined" 
                    value={me.lastName} 
                    onChange={changeLastName} 
                    error={!validateLastName()} 
                    helperText={!validateLastName() ? "Invalid last name." : ""} 
                  />
                  {/* <TextField 
                    className="form-field" 
                    id="outlined-basic" 
                    label="Email address" 
                    variant="outlined" 
                    value={me.email} 
                    onChange={changeEmail} 
                    error={!validateEmail()} 
                    helperText={!validateEmail() ? "Invalid email." : ""} 
                  /> */}
                  <TextField 
                    className="form-field" 
                    id="outlined-basic" 
                    label="Email address" 
                    variant="outlined" 
                    value={me.email} 
                    helperText="Contact support to change your email address." 
                  />
                  <br/>
                  {/* <TextField 
                    className="form-field no-margin"
                    id="outlined-basic" 
                    label="New password" 
                    type="password" 
                    variant="outlined" 
                    value={me.password} 
                    onChange={changePassword} 
                    error={!validatePassword()} 
                    helperText={!validatePassword() ? "Invalid password." : ""} 
                  />
                  
                  <PasswordStrengthBar password={me.password} className="password-strength" />
                  
                  <TextField 
                    className="form-field" 
                    id="outlined-basic" 
                    label="Confirm new password" 
                    type="password" 
                    variant="outlined" 
                    value={me.confirmPassword} 
                    onChange={changeConfirmPassword} 
                    error={!validateConfirmPassword()} 
                    helperText={!validateConfirmPassword() ? "Passwords do not match." : ""} 
                  /> */}
                  <Button disabled={!validateForm()} variant="contained" onClick={submitForm}>Save</Button>
                </div>
              </div>
            </div>
          }
          { tab == 'benefits' &&
            <div className="section benefits">
              { false && 
                membershipBenefits.map((mb, i) => {
                  return <div className="block-container" key={`block${i}`}>
                    <div className="block" onClick={() => copyCode(mb)}
                      style={{
                        backgroundImage: mb.Image.data ? `url(${apiAddress}${mb.Image.data.attributes.url})` : 'none',
                        backgroundColor: mb.Image.data ? 'rgb(206, 206, 206' : mb.color,
                        borderColor: mb.Image.data ? 'rgb(206, 206, 206' : mb.color
                      }}
                    >
                      <div className="cover">
                        <div className="benefit-title">{mb.Title}</div>
                        <p>{mb.Description}</p>
                        <div className="tip">{mb.copying ? 'Copied to clipboard!' : 'Click to copy'}</div>
                        <div className="input-outline">
                          <input value={mb.Code} className="code-input" readOnly/>
                        </div>
                      </div>
                        

                      
                    </div>
                  </div>
                })
              }
              { isMember && 
                membershipBenefits.map((mb, i) => {
                  return <div className="block-container" key={`block${i}`}>
                    <div className="block"
                      style={{ background: 'rgb(243 243 243)'}}
                    >
                      {
                        mb.Image.data &&
                        <div className="upper" style={{ backgroundImage: `url(${apiAddress}${mb.Image.data.attributes.url})` }}></div>
                      }
                      <div className="lower">
                        <div className="info">
                          <div className="benefit-title">{mb.Title}</div>
                          <p>{mb.Description}</p>
                        </div>
                        <div className="actions">
                          { mb.Code &&
                            <div className="code-container">
                              <div className="input-outline">
                                <input value={mb.Code} className="code-input" readOnly/>
                                <button className="copy-button" onClick={() => copyCode(mb)}>{mb.copying ? 'Copied!' : 'Copy'}</button>
                              </div> 
                            </div>
                          }
                          <button onClick={()=> visitLink(mb)} className="visit-site">Visit Site</button>
                        </div>
                      </div>
            

                      
                    </div>
                  </div>
                })
              }
              {
                !isMember &&
                <div className="hide-container">
                  <p className="center-text"><h2>Your membership is inactive</h2><a className="renew-link" onClick={routeProfile}>Renew your membership</a> to gain access to member benefits.</p>
                  <div className="cover">
                    {
                      [1,1,1,1,1,1].map((mb, i) => {
                        return <div className="block-container" key={`block${i}`}>
                          <div className="block">
                              <div className="benefit-title">Test</div>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                              <div className="tip">Click to copy</div>
                              <div className="input-outline">
                                <input value='XXXXXX' className="code-input" readOnly/>
                              </div>

                            
                          </div>
                        </div>
                      })
                    }
                  </div>
                </div>
                
                
              }
            </div>
          }
        </React.Fragment>
      }
     
    </div>
  );
}

export default Profile;
