import './BoardMembers.scss';
import React from "react";
import { get, apiAddress } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import DefaultUser from '../../assets/default-user.png'; // Import using relative path

function BoardMembers() {
  
  const [pageContent, setPageContent] = React.useState({});

  React.useEffect(() => {
    document.title = "About - CAPN";
    
    get('board-members').then(res => {
      setPageContent({ 
        ...pageContent,
        boardMembers:res
      });
    });
  
  }, []);

  return (
    <div className="BoardMembers Page">
      <BannerTitle title={'Board Members'} />
      { !pageContent && <Loader loading={!pageContent}/> }
      {
        pageContent &&
        <React.Fragment>
          <div className="section">
            <h2>Executive Board Officers</h2>
            <div className="grid">
              {pageContent.boardMembers?.filter(u => !!u.Executive).sort((a,b)=> a.Order - b.Order).map((member, index) => {
                return <div key={`member${index}`} className="tile" style={{ backgroundImage: `url(${member.Portrait.data ? apiAddress+member.Portrait.data.attributes.url : DefaultUser} )` }}>
                  <div className="banner">
                    <div className="name">{member.Name}</div>
                    <div className="title">{member.Title}</div>
                    <div className="title">{member.Province}</div>
                  </div>
                </div>
              })}
            </div>
          </div>
          <div className="section">
            <h2>Provincial &amp; Territorial Board Members</h2>
            <div className="grid">
              {pageContent.boardMembers?.filter(u => !u.Executive).sort((a,b)=> a.Order - b.Order).map((member, index) => {
                return <div key={`member${index}`} className="tile" style={{ backgroundImage: `url(${member.Portrait.data ? apiAddress+member.Portrait.data.attributes.url : DefaultUser} )` }}>
                  <div className="banner">
                    <div className="name">{member.Name}</div>
                    <div className="title">{member.Province}</div>
                  </div>
                </div>
              })}
            </div>
          </div>
        </React.Fragment>
      }
        
    </div>
  );
}

export default BoardMembers;
