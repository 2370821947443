import './CustomMarkdown.scss';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

function CustomMarkdown(props) {
  return (
    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={props.content}/> 
  );
}

export default CustomMarkdown;
