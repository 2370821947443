import './Privacy.scss';
import React from "react";
import { get } from '../../index.js';
import Loader from '../../components/Loader/Loader';
import BannerTitle from '../../components/BannerTitle/BannerTitle';
import CustomMarkdown from '../../components/CustomMarkdown/CustomMarkdown';

function Privacy() {
  
  const [pageContent, setPageContent] = React.useState(null);

  React.useEffect(() => {
    document.title = "Privacy Policy - CAPN";
    get('privacy').then(res => {
      setPageContent(res);
    });
  }, []);

  return (
    <div className="Privacy Page">
      <BannerTitle title={'Privacy Policy'}/>
      { !pageContent && <Loader loading={!pageContent}/> }
      { pageContent && 
        <React.Fragment>
          <div className="section">
              <CustomMarkdown content={pageContent.Privacy_Policy}/>
          </div>
        </React.Fragment>
      }
    </div>
  );
}

export default Privacy;
